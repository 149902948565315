<template>
    <div id="page-managemenu" class="page-managemenu">
        <b-row class="managemenurow">
            <b-col class="managemenucol">
                <b-card class="cardpresentation">
                    <div class="cardheader d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <span class="cardnumber rounded-circle bg-green">01</span>
                            <h5 class="cardtitle">Apresentação</h5>   
                        </div>
                        <div>
                            <!-- <b-button variant="green" class="text-white" v-b-modal.modalPreviewWhatsapp @click="setPreviewMessages('presentation')">Visualizar</b-button> -->
                        </div>
                    </div>
                    <!-- <div class="cardsubheader border-bottom-1 d-flex text-center align-items-center flex-column p-4">
                        <div>A apresentação ocorre quando um usuário envia uma mensagem ao canal.</div>
                        <div>Aqui você pode escolher uma imagem, texto ou ambos, para a apresentação do canal.</div>
                        <div>Aqui você pode digitar o texto de apresentação do canal.</div>
                    </div> -->
                    <!-- <div class="addimage">
                        <b-form-file
                                v-model="sendInputMedia"
                                :state="Boolean(sendInputMedia)"
                                placeholder="Adicionar Arquivo"
                                drop-placeholder="Adicionar Arquivo"
                                @input="setInputMedia"
                                class="inputfile"
                                role="button"
                                >
                                <template #placeholder>
                                    <div class="cardpresentationimg">
                                        <div class="d-flex flex-column align-items-center">
                                            <b-icon class="cardpresentationicon" icon="image"></b-icon>
                                            <div class="cardpresentationtext">Adicionar Imagem</div>
                                        </div>
                                    </div>
                                </template>
                                <template slot="file-name">
                                    <div style="height:325px;">
                                        <b-img style="max-height: 250px; width: 100%" :src="img"></b-img>
                                    </div>
                                </template>
                                </b-form-file>
                    </div> -->
                    <div class="addimage" v-if="menu.imageEnabled">
                        <b-form-file id="menuImgUpload" style="display:none" accept="image/*" @input="selectMenuImage"></b-form-file>
                        <label for='menuImgUpload' class="w-100">
                            <div class="cardpresentationimg" v-if="!menu.media[0].url">
                                <div class="d-flex flex-column align-items-center">
                                    <b-icon class="cardpresentationicon" icon="image"></b-icon>
                                    <div class="cardpresentationtext">Adicionar Imagem</div>
                                </div>
                            </div>
                            <div style="max-height:325px;" role="button" v-else>
                                <b-img style="max-height: 325px; width: 100%" :src="menu.media[0].url"></b-img>
                            </div>
                        </label>
                    </div>
                    <div class="cardpresentationcontent">
                        <div class="cardpresentationcontenttop d-flex justify-content-between align-items-center">
                            <b-form-checkbox class="cardpresentationcheckbox" v-model="menu.imageEnabled">Imagem</b-form-checkbox>
                        </div>
                        <div class="position-relative">
                            <b-form-group label="Cabeçalho:" label-class="text-purple fw-bold" v-if="menu.header">
                                <b-form-input class="px-4" v-model="menu.header.text" placeholder="Escreva seu texto de cabeçalho" maxlength="60" />
                                <b-icon class="toggle-emoji darkenTextOnHover mb-1" @click="toggleDialogEmojiHeader" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                <VEmojiPicker @select="selectEmojiHeader" lang="pt-BR" v-show="showDialogHeader" :i18n="i18n" />
                            </b-form-group>
                        </div>
                        <div class="text-danger small-text mt-1" v-if="menu.header?.text?.length >= 60">
                            O limite de caracteres (60) foi atingido!
                        </div>
                        <div class="position-relative mt-2">
                            <b-form-group label="Texto:" label-class="text-purple fw-bold" v-if="menu.body">
                                <b-form-textarea class="px-4" v-model="menu.body.text" placeholder="Escreva seu texto" maxlength="1024" rows="5" max-rows="5" no-resize></b-form-textarea>
                                <b-icon class="toggle-emoji darkenTextOnHover" @click="toggleDialogEmojiTextarea" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                <VEmojiPicker @select="selectEmojiTextarea" lang="pt-BR" v-show="showDialogTextarea" :i18n="i18n" />
                            </b-form-group>
                        </div>
                        <div class="text-danger small-text mt-1" v-if="menu.body?.text?.length >= 1024">
                            O limite de caracteres (1024) foi atingido!
                        </div>
                        <div class="position-relative mt-2">
                            <b-form-group label="Rodapé:" label-class="text-purple fw-bold" v-if="menu.footer">
                                <b-form-input class="ps-4 pe-5" v-model="menu.footer.text" placeholder="Escreva seu texto de rodapé" maxlength="60" />
                                <b-icon class="toggle-emoji darkenTextOnHover mb-1" @click="toggleDialogEmojiFooter" icon="emoji-smile" font-scale="1.25" ></b-icon>
                                <VEmojiPicker @select="selectEmojiFooter" lang="pt-BR" v-show="showDialogFooter" :i18n="i18n" />
                            </b-form-group>
                        </div>
                        <div class="text-danger small-text mt-1" v-if="menu.footer?.text?.length >= 60">
                            O limite de caracteres (60) foi atingido!
                        </div>
                        <div class="mt-2">
                            <b-form-group label="Texto do Botão:" label-class="text-purple fw-bold">
                                <b-form-input class="px-4" v-model="menu.action.button" maxlength="20" />
                                        <div class="text-danger small-text mt-1" v-if="menu.action?.button?.length >= 20">
                                    O limite de caracteres (20) foi atingido!
                                </div>
                            </b-form-group>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                <!-- <b-form-checkbox class="cardpresentationcheckbox">Texto</b-form-checkbox> -->
                            </div>
                            <div>
                                <!-- <b-button class="managemenubtngreen" v-b-modal.modal-evasivas>Evasivas</b-button> -->
                                <b-button class="managemenubtnpink" v-b-modal.modal-tags>Tags</b-button>
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col class="managemenucol">
                <b-card class="cardmainmenu">
                    <div class="cardheader d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <span class="cardnumber rounded-circle bg-green">02</span>
                            <h5 class="cardtitle">Sessões</h5>   
                        </div>
                        <div>
                            <!-- <b-button variant="green text-white" v-b-modal.modalPreviewWhatsapp @click="setPreviewMessages('sections')">Visualizar</b-button> -->
                        </div>
                    </div>
                    <div class="cardmainmenucontent">
                        <b-button class="managemenubtngreen px-3 w-100 mb-3" @click="addSection">Adicionar</b-button>
                        <draggable tag="ul" :list="menu.action.sections" class="list-group" handle=".handle">
                            <li
                            class="d-flex mb-3"
                            v-for="(item,index) in menu.action.sections"
                            :key="index"
                            >
                                <b-input-group class="me-2">
                                    <template #append>
                                        <b-button class="handle rounded-0 rounded-end" v-b-tooltip.bottom title="Alterar Ordem">
                                            <b-icon icon="arrows-move"></b-icon>
                                        </b-button>
                                    </template>
                                    <b-form-input v-model="item.title" maxlength="20" />
                                </b-input-group>
                                <b-button variant="green" class="text-white me-2" v-b-tooltip.bottom="{ customClass: 'tooltip-edit' }" title="Editar" v-b-modal.modalEditSection @click.prevent="selectSection(item)"><b-icon icon="pencil-square"></b-icon></b-button>
                                <b-button variant="red" class="text-white" v-b-tooltip.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modalRemove @click.prevent="selectSection(item)"><b-icon icon="trash"></b-icon></b-button>
                            </li>
                        </draggable>
                    </div>
                </b-card>
                <b-card class="cardbtns">
                        <b-button class="managemenubtngreen" @click="saveMenu(true)">Avançar</b-button>
                        <b-button class="managemenubtnwhite" @click="goToMain">Cancelar</b-button>
                </b-card>
            </b-col>
        </b-row>
        <b-modal id="modal-carimbo" title="Carimbo">
            <b-row class="modalinputrow">
                <b-col>
                    <b-form-group label="Espaçamento Horizontal (X):">
                        <b-form-input></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Espaçamento Vertical (Y):">
                        <b-form-input></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="modalinputrow">
                <b-col>
                    <b-form-group label="Fonte:">
                        <b-form-select></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-row>
                        <b-col>
                            <b-form-group label="Tamanho:">
                                <b-form-select></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Cor:">
                                <b-form-input class="forminput" value="hsl(43deg 89% 38%)" type="color" list="presetColors"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div>
                <b-button class="managemenubtngreen">Salvar</b-button>
                <b-button class="managemenubtnwhite" @click="$bvModal.hide('modal-carimbo')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-evasivas" ref="modal-evasivas" class="managemenu-modal" title="Evasivas">
            <b>O que são evasivas?</b>
            <p>São aquelas palavras não identificadas pelo canal que o contato informa! A lista abaixo possui algumas palavras utilizadas pelo contato para tentar acessar os itens deste menu!</p>
            <div class="evasivas" v-for="item in evasivas" :key="item.id">{{item.name}}</div>
        </b-modal>
        <b-modal id="modal-tags" ref="modal-tags" class="managemenu-modal" title="Tags" header-class="py-0" body-class="py-0">
            <div class="border-bottom py-2" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
        <b-modal id="modalEditSection" ref="modalEditSection" size="lg" class="managemenu-modal" header-class="py-0" :title="`Editar Sessão - ${objAux.title}`" v-if="sectionSelected">
            <b-form-group class="" label="Opções:" label-class="fw-bold text-purple">
                <div class="border border-grey rounded">
                    <draggable tag="ul" :list="objAux.rows" class="list-group" handle=".handle">
                        <li
                        class="d-flex"
                        v-for="(option,index) in objAux.rows"
                        :key="index"
                        >
                            <b-row class="m-0 w-100 border-bottom border-grey p-3 d-flex align-items-end">
                                <b-col class="p-0 pe-3">
                                    <b-form-group label="Título:" label-class="text-purple">
                                        <b-form-input v-model="option.title" :state="option.title.length > 24 ? false : null"></b-form-input>
                                        <b-form-invalid-feedback id="input-live-feedback">
                                            O Título não pode ter mais que 24 caracteres
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    
                                </b-col>
                                <b-col class="p-0 pe-3">
                                    <b-form-group label="Descrição:" label-class="text-purple">
                                        <b-form-textarea
                                        v-model="option.description"
                                        rows="1"
                                        max-rows="1"
                                        no-resize
                                        style="height: 0.5em"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                                <b-col class="p-0 w-auto" cols="3">
                                    <b-button class="handle me-2" v-b-tooltip.bottom title="Alterar Ordem">
                                        <b-icon icon="arrows-move"></b-icon>
                                    </b-button>
                                    <!-- <b-button class="me-2" variant="green" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit' }" title="Configurações" @click="selectOption(option); getRedirectData()" v-b-modal.modalOptionConfig>
                                        <b-icon class="text-white" icon="gear"></b-icon>
                                    </b-button> -->
                                    <b-button variant="red" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove' }" title="Remover" v-b-modal.modalRemove @click="selectOption(option)">
                                        <b-icon class="text-white" icon="trash"></b-icon>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </li>
                    </draggable>
                    <div class="p-3">
                        <b-button class="managemenubtngrey me-2" @click="addOption(objAux)">Adicionar</b-button>
                        <b-button class="managemenubtngreen" @click="saveOptions(objAux)">Avançar</b-button>
                    </div>
                </div>
            </b-form-group>
        </b-modal>
        <b-modal id="modalPreviewWhatsapp" ref="modalPreviewWhatsapp" title="Visualizar Whatsapp" hide-footer v-if="previewMessages">
            <Whatsapp :messages="previewMessages" :mediaURL="mediaURL" />
        </b-modal>
        <b-modal id="modalOptionConfig" ref="modalOptionConfig" :title="`${optionSelected.title || 'Opção'} - Configurações`" hide-footer v-if="optionSelected">
            <b-form-group label="Direcionar para:">
                <b-form-select v-model="optionSelected.action">
                    <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                    <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                    <b-form-select-option :value="undefined">Não direcionar</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-form-group class="mt-2" :label="`Selecione o ${ optionSelected.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="optionSelected.action">
                <b-form-select v-model="optionSelected.redirectId" v-if="optionSelected.action=='redirectDepartment'">
                    <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                </b-form-select>
                <b-form-select v-model="optionSelected.redirectId" v-else-if="optionSelected.action=='redirectOperator'">
                    <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
        </b-modal>
        <b-modal id="modalRemove" ref="modalRemove" :title="`Remover ${ optionSelected ? 'Opção' : 'Sessão' }`" header-class="py-0" hide-footer v-if="sectionSelected || optionSelected">
            <div>
                Tem certeza de que deseja remover esta {{ optionSelected ? 'opção' : 'sessão' }}?
            </div>
            <div class="mt-2">
                <b-button class="managemenubtngreen" @click="removeFromArray(optionSelected ? objAux.rows : menu.action.sections, optionSelected ? optionSelected : sectionSelected)">Remover</b-button>
                <b-button class="managemenubtnwhite" @click="$bvModal.hide('modalRemove')">Cancelar</b-button>
            </div>
        </b-modal>
        <datalist id="presetColors">
            <option>#b8860b</option>
            <option>#018b8c</option>
            <option>#ff4500</option>
            <option>#ce5c5c</option>
            <option>#a0522c</option>
            <option>#ab5aa7</option>
            <option>#87cefa</option>
            <option>#ff69b3</option>
            <option>#ffd701</option>
            <option>#32cd33</option>
            <option>#ff8c00</option>
            <option>#d63031</option>
            <option>#a29bfe</option>
            <option>#dfe6e9</option>
            <option>#000000</option>
        </datalist>
    </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import draggable from 'vuedraggable'
import api, { getMenu } from '../services/apiService.js'
import Whatsapp from './whatsapp.vue';
import structuredClone from '@ungap/structured-clone';
import { getToken } from '../services/userService.js'

export default {
    components: {
        VEmojiPicker,draggable,Whatsapp
    },
    props: [
        'user',
        'mediaURL',
        'menuId'
    ],
    mounted: function () {
        this.init()
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide',(bvEvent, modalId) => {
                if(modalId != 'modalOptionConfig' && modalId != 'modalRemove') {
                    this.objAux = {}
                    this.sectionSelected = null
                    this.optionSelected = null
                }
            })
        },
        async init() {
            this.onCloseModal()

            if (this.menuId) {
                this.getMenu(this.menuId)
            } else {
                this.getMenus()
            }
        },
        async getMenus() {
            const resp = await api.getMenus(this.user.channelId || this.user.roleId)
            console.log('respMenus',resp)
            if(resp.statusCode != 200) {
                return this.$emit('msg', {
                    text:'Ocorreu um erro!',
                    success: false,
                })
            }
            if(!resp.menus.length) {
                const menu = { 
                    name: "Menu Lista",
                    topMenuId: undefined,
                    refObjectMenus: undefined,
                    channelId: this.user.channelId || this.user.roleId,
                    interactive: {
                        type: 'list',
                        header: {
                            text: ''
                        },
                        body: {
                            text: ''
                        },
                        footer: {
                            text: ''
                        },
                        action: {
                            sections: []
                        }
                    },
                }
                const respCreate = await api.createMenu(menu)
                // console.log('resp createMenu',resp)
                if((respCreate.statusCode != 200 && respCreate.statusCode != 201) || !respCreate.menu) {
                    this.$emit('msg', {
                        text: "Ocorreu um erro ao criar menu!",
                        success: false
                    })
                } else {
                    respCreate.menu.interactive.media = respCreate.menu.media
                    respCreate.menu.interactive.imageEnabled = respCreate.menu.imageEnabled
                    this.menu = respCreate.menu.interactive
                    this.menu._id = respCreate.menu._id
                }
            } else {
                const mainMenuIndex = resp.menus.findIndex(el => !el.topMenuId)
                resp.menus[mainMenuIndex].interactive.media = resp.menus[mainMenuIndex].media
                resp.menus[mainMenuIndex].interactive.imageEnabled = resp.menus[mainMenuIndex].imageEnabled
                const menu = resp.menus[mainMenuIndex].interactive
                if(!menu.body)
                    menu.body = { text: '' }
                if(!menu.header)
                    menu.header = { type: 'text', text: '' }
                if(!menu.footer)
                    menu.footer = { text: '' }
                if(!menu.type)
                    menu.type = 'list'
                if(!menu.action)
                    menu.action = { sections: [] }
                if(!menu.action.sections)
                    menu.action.sections = []
                this.menu = menu
                this.menu._id = resp.menus[mainMenuIndex]._id
            }
            console.log('menu',this.menu)
        },
        async getMenu(menuId) {
            const resp = await api.getMenu(menuId)
            if(resp.statusCode == 200) {
                const menu = resp.menu.interactive
                if(!menu.body)
                    menu.body = { text: '' }
                if(!menu.header)
                    menu.header = { type: 'text', text: '' }
                if(!menu.footer)
                    menu.footer = { text: '' }
                if(!menu.type)
                    menu.type = 'list'
                if(!menu.action)
                    menu.action = { sections: [] }
                if(!menu.action.sections)
                    menu.action.sections = []
                this.menu = menu
                this.menu._id = menuId
            }  
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode != 200) {
                this.operators = []
                return
            }
            this.operators = resp.operators
        },
        async getDepartments() {
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode != 200) {
                this.departments = []
                return
            }
            this.departments = resp.departments
        },
        async getRedirectData() {
            this.getOperators()
            this.getDepartments()
        },
        selectSection(section) {
            this.sectionSelected = section;
            this.objAux = structuredClone(section);
        },
        selectOption(option) {
            this.optionSelected = option
        },
        toggleDialogEmojiTextarea() {
            this.showDialogTextarea = !this.showDialogTextarea;
        },
        toggleDialogEmojiHeader() {
            this.showDialogHeader = !this.showDialogHeader;
        },
        toggleDialogEmojiFooter() {
            this.showDialogFooter = !this.showDialogFooter;
        },
        toggleDialogEmojiIcon() {
            this.showDialogIcon = !this.showDialogIcon;
        },
        selectEmojiTextarea(emoji) {
            if(!this.menu.body.text)
                this.menu.body.text = ""
            this.menu.body.text += emoji.data
            this.toggleDialogEmojiTextarea()
        },
        selectEmojiHeader(emoji) {
            if(!this.menu.header.text)
                this.menu.header.text = ""
            this.menu.header.text += emoji.data
            this.toggleDialogEmojiHeader()
        },
        selectEmojiFooter(emoji) {
            if(!this.menu.footer.text)
                this.menu.footer.text = ""
            this.menu.footer.text += emoji.data
            this.toggleDialogEmojiFooter()
        },
        setInputMedia(e) {
            console.log(e)
        },
        async saveMenu(advance = false) {
            if(!this.menu.action.button) {
                let msg = {
                    text: 'O campo "Texto do Botão" precisa ser preenchido!',
                    success: false
                }
                return this.$emit('msg',msg)
            }
            let menuResp = await api.getMenu(this.menu._id)
            if(menuResp.statusCode == 200) {
                let menu = menuResp.menu
                menu.imageEnabled = this.menu.imageEnabled
                menu.media = this.menu.media
                if(!menu.topMenuId) 
                    menu.topMenuId = undefined
                if(!menu.refObjectMenus)
                    menu.refObjectMenus = undefined
                if(!menu.interactive)
                    menu.interactive = { type: 'list', header: {}, action: {} }
                else if (menu.interactive.type != 'list')
                    menu.interactive.type = 'list'
                if(!menu.interactive.header)
                    menu.interactive.header = {}
                if(!menu.interactive.action)
                    menu.interactive.action = {}
                if(!menu.interactive.header?.type)
                    menu.interactive.header.type = 'text'
                if(menu.interactive.action.buttons) delete menu.interactive.action.buttons
                menu.menus = ["null"]
                await api.updateMenu({ menus: [ menu ] })
            }

            let presentationResp = await this.savePresentation()
            if(presentationResp != 200) {
                let msg = {
                    text:'Revise os dados de Apresentação e tente novamente!',
                    success: false,
                }
                return this.$emit('msg',msg) 
            }

            let sectionsResp = await this.saveSections()
            if(sectionsResp != 200) {
                let msg = {
                    text:'Revise os dados de Sessões e tente novamente!',
                    success: false,
                }
                return this.$emit('msg',msg) 
            }
            // let msg = {
            //     text:'Menu salvo com sucesso!',
            //     success: true,
            // }
            // this.$emit('msg',msg) 
            if(advance)
                this.goToMenuCustom(this.menuId)
        },
        async savePresentation() {
            let resp = await api.updatePresentation(
                { textPresentation: this.menu.body.text, textButton: this.menu.action.button, header: this.menu.header, footer: this.menu.footer },
                this.menu._id
            )
            return resp.statusCode
        },
        async saveSections() {
            let resp = await api.updateSections(this.menu.action.sections,this.menu._id)
            // console.log('sectionsResp',resp)
            return resp.statusCode
        },
        addSection() {
            if(this.menu.action.sections.length >= 10) {
                let msg = {
                    text:'O número máximo (10) de Sessões foi atingido!',
                    success: false,
                }
                return this.$emit('msg',msg)
            }
            this.menu.action.sections.push({
                rows: [],
                title: ''
            })
        },
        addOption(section) { // add option to a row
            section.rows.push({ title: '', description: '' })
        },
        saveOptions(section) {
            if(section.rows.length) {
                let error = false
                section.rows.map(el=>{
                    if(el.title.length > 24) {
                        el.invalid = true
                        let msg = {
                            text:'O Título não pode ter mais que 24 caracteres',
                            success: false,
                        }
                        this.$emit('msg',msg)
                        error = true
                        return el
                    }
                })
                if(error) return
            }
            this.sectionSelected.rows = section.rows
            this.$refs['modalEditSection'].hide()
        },
        removeFromArray(array,element) { // remove option from a section
            let index = array.indexOf(element)
            array.splice(index, 1)

            if(this.optionSelected) {
                this.optionSelected = null
            }else if(this.sectionSelected) {
                this.sectionSelected = null
            }
            this.$refs['modalRemove'].hide()
        },
        setPreviewMessages(type) {
            // optionSelected ? [] : [{type: 'listPresentation', body: menu.body.text, button: menu.action.button, fromMe: true}]
            switch(type) {
                case 'presentation':
                    this.previewMessages = [
                        {
                            type: 'listPresentation',
                            body: this.menu.body.text,
                            button: this.menu.action.button,
                            fromMe: true
                        }
                    ]
                    break
                case 'sections':
                    this.previewMessages = [
                        {
                            type: 'listSections',
                            button: this.menu.action.button,
                            sections: this.menu.action.sections,
                            fromMe: true
                        }
                    ]
                    break
            }
        },
        async selectMenuImage(e) {
            console.log(e)
            if (e.size > 10000000) {
                let msg = {
                    text: "O tamanho máximo permitido para envios é de 10 MB",
                    danger: true
                }
                this.$emit('msg',msg)
            }
            const formData = new FormData();
            e.filename = e.name
            formData.append('file',e)
            let typefile = e['type'].split('/')[0]
            let token = getToken()
            let headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber':this.clientSelected.clientNumber }
            let uploadResp = await api.upload(formData,headers)
            let url = uploadResp.file.uploadDir
            if(!this.menu.media?.length)
                this.menu.media.push({ url: '' })
            let obj = {
                url,
                type: typefile,
                filename: e.filename,
                ext: e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            }
            this.$set(this.menu.media,0,obj)
        },
        async goToMain() {
            this.$router.replace({path:'/'})
        },
        goToMenuCustom(menuId = null) {
            if(menuId) {
                this.$router.replace({path:`/manageMenuCustom?id=${menuId}`})
            } else {
                this.$router.replace({path:`/manageMenuCustom`})
            }
        }
    },
    data() {
        return{
            sendInputMedia: [],
            sectionSelected: null,
            optionSelected: null,
            objAux: {},
            showDialogTextarea: false,
            showDialogHeader: false,
            showDialogFooter: false,
            showDialogIcon: false,
            img: '',
            operators: [],
            departments: [],
            previewMessages: null,
            menu : {
                action: { 
                    sections: [
                        {
                            rows: []
                        }
                    ]
                },
                body: {},
                type: ''
            },
            evasivas: [
                {
                    name: 'Me',
                },
                {
                    name: 'Não',
                },
                {
                    name: 'Preciso',
                },
            ],
            tags: [
                {
                    name: '%NOME_CLIENTE%',
                    description: 'Identifica nome do contato',
                },
                {
                    name: '%OPERADOR%',
                    description: 'Identifica nome do operador (caso exista operador em atendimento com o contato)',
                },
                {
                    name: '%DTN%',
                    description: 'Dia, Tarde ou Noite',
                },
                // {
                //     name: '%Número%',
                //     description: 'Identifica número do contato',
                // },
                // {
                //     name: '#',
                //     description: 'Voltar ao Menu Anterior',
                // },
                // {
                //     name: '*',
                //     description: 'Finaliza Atendimento no Painel Principal / Volta ao Menu Principal',
                // },
            ],
            i18n: {
                search: 'Pesquisar...',
                categories: {
                    Activity: "Atividades",
                    Flags: "Bandeiras",
                    Foods: "Comida",
                    Frequently: "Frequentes",
                    Objects: "Objetos",
                    Nature: "Natureza",
                    Peoples: "Pessoas",
                    Symbols: "Símbolos",
                    Places: "Locais"
                }
            }
        }
    }
}
</script>

<style>
    .cardpresentationcheckbox input{
        margin-right: 10px;
    }
    .cardpresentationcheckbox label{
        color: #555;
    }
    .emoji-picker #Categories {
        order: 5;
    }
    .emoji-picker #InputSearch {
        order: 4;
    }
    .emoji-picker .border{
        border: none !important;
    }
    .emoji-picker .emoji{
        display: flex !important;
        justify-content: center !important;
    }
    .emoji-picker .container-emoji{
        overflow: auto !important;
    }
    #modal-carimbo .close, #modal-evasivas .close, #modal-tags .close, #modalEditSection .close{
        border: none;
        background-color: transparent;
        font-size: 2em;
        color: #555;
    }
    #modal-carimbo .modal-title, #modal-evasivas .modal-title, #modal-tags .modal-title, #modalEditSection .modal-title{
        color: #555 !important;
    }
    #modal-carimbo .modal-footer, #modal-evasivas .modal-footer, #modal-tags .modal-footer,  #modalEditSection .modal-footer{
        display: none;
    }
    #modal-carimbo .col-form-label, #modal-evasivas .col-form-label, #modal-tags .col-form-label, #modalEditSection .col-form-label{
        color: #777;
        font-weight: 500;
    }
    .modal-content{
        border: none
    }
    .addimage .custom-file-input{
        display: none;
    }
    .addimage .custom-file-label{
        display: block;
        cursor: pointer;
    }
    .tooltip-orderChange>.tooltip-inner {
        background-color: hsl(208deg 57% 70%);
        color: #fff;
    }
</style>

<style scoped>
    .page-managemenu .card-body{
        padding: 0;
    }
    .managemenurow{
        display: flex;
        justify-content: space-between;
    }
    .card{
        border: none;
    }
    .cardheader{
        padding: 30px;
        display: flex;
        align-items: center;
        border-bottom: 0.5px solid #eee;
    }
    .cardnumber{
        margin-right: 15px;
        color: #fff;
        display:inline-block;
        font-size:25px;
        font-weight: 500;
    }
    .cardnumber:before,
    .cardnumber:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;

        padding-top:50%;
        padding-bottom:50%;
    }
    .cardnumber:before {
        padding-left:12px;
    }
    .cardnumber:after {
        padding-right:12px;
    }
    .cardtitle{
        display: inline;
        margin-top: 6px;
        color: #333;
    }
    .cardsubheader{    
        font-size: 0.9em;
        color: hsl(252deg 3% 68%);
        border-bottom: 0.5px solid #eee;
    }
    .cardpresentationimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        color: #fff;
        background-color: hsl(249deg 9% 28%);
        padding: 79px;
        cursor: pointer;
        width: 100%;
    }
    .custom-file-label:hover .cardpresentationimg{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .cardpresentationicon{
        font-size: 4em;
    }
    .cardpresentationtext{
        font-size: 1.2em;
    }
    .cardpresentationcontent{
        padding: 20px 30px;
    }
    .cardmainmenucontent{
        padding: 30px;
    }
    .cardpresentationcontenttop{
        margin-bottom: 20px;
    }
    .managemenubtngreen:not(:last-child) {
        margin-right: 10px;
    }
    .managemenubtngreen{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        padding: 5px 20px;
    }
    .managemenubtngrey{
        background-color: hsl(208deg 7% 46%);
        border-color: hsl(208deg 7% 46%);
        padding: 5px 20px;
    }
    .managemenubtnpink{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        padding: 5px 20px;
    }
    .managemenubtnred{
        background-color: hsl(360deg 83% 69%);
        border-color: hsl(360deg 83% 69%);
        padding: 5px 20px;
    }
    .managemenubtnwhite{
        background-color: #fff;
        color: #777;
        border-color: #eee;
    }
    .managemenubtnwhite:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    select, input, textarea{
        border-color: #eee;
        color: #555;
    }
    .cardmainmenuitem{
        display: flex;
        align-items: center;
        text-align: center;
        height: 50px;
        border-radius: 2px;
    }
    .cardmainmenuitem:not(:last-child) {
        margin-right: 18px;
    }
    .cardmainmenunumber{
        width: 60px;
    }
    .cardmainmenuicon{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        width: 4em;
    }
    .cardmainmenuicon:hover{
        background-color: hsl(142deg 30% 50%);
        border-color: hsl(142deg 30% 50%);
    }
    .cardmainmenuname{
        margin: 0 !important;
        text-align: left;
    }
    .cardmainmenueyebtn{
        background-color: hsl(339deg 81% 69%);
        border-color: hsl(339deg 81% 69%);
        font-size: 1.2em;
    }
    .cardmainmenueditbtn{
        background-color: hsl(93deg 44% 65%);
        border-color: hsl(93deg 44% 65%);
        font-size: 1.2em;
    }
    .cardmainmenubtns{
        display: flex;
        justify-content: flex-end;
    }
    .cardbtns{
        margin-top: 25px;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .form-control, .custom-select{
        padding: 0.375rem 0.75rem;
    }
    .modalinputrow{
        margin-bottom: 10px;
    }
    .modal select{
        width: 100%;
    }
    .evasivas{
        background-color: hsl(0deg 0% 93%);
        color: hsl(0deg 0% 60%);
        width: fit-content;
        padding: 2px 10px;
        border-radius: 5px;
        display: inline;
    }
    .evasivas:not(:last-child) {
        margin-right: 10px;
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0.3em;
        right: 1em;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .emoji-picker{
        position: absolute;
        bottom: 40px;
        right: 0;
        width: 250px;
        z-index: 1;
    }
    .iconemojipicker{
        bottom: 0;
    }
</style>